<template>
  <router-view />
  <div id="bottom">
    <!-- <div>版本号：1.0.0</div> -->
    <TabBar />
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import TabBar from "@/components/Mobile/TabBar.vue";
import Wxconfig from "@/config/Mobile/WXshare";
require('./less/mobile/common.less');
const logo_img = require('@/assets/logo.jpg');

@Options({
  components: {
    TabBar,
  },
  updated() {
    window.document.title = this.$route.name;
    const link = this.getUrl();
    Wxconfig.wxShowMenu({
      titles: '归农',
      titles_circle: '归农',
      descs: '不负农人苦心，分享自然真味!我们一起归农...',
      link: link,
      imgUrl: 'https://gn-rrd.gnso.cn/2020/01/15/Fg2kPnOoWXygYdWWsSMNObSZ5YVV.jpg',
      ajaxUrl: 'https://wfx.2268mall.com/weixin/sign_package_web.json?url=' + encodeURIComponent(link),
    });
  },
  created() {
    const link = this.getUrl();
    // console.log(link);
    Wxconfig.wxShowMenu({
      titles: '归农',
      titles_circle: '归农',
      descs: '不负农人苦心，分享自然真味!我们一起归农...',
      link: link,
      imgUrl: 'https://gn-rrd.gnso.cn/2020/01/15/Fg2kPnOoWXygYdWWsSMNObSZ5YVV.jpg',
      ajaxUrl: 'https://wfx.2268mall.com/weixin/sign_package_web.json?url=' + encodeURIComponent(link),
    });
  },
  mounted() {
    this.$forceUpdate();
    const link = this.getUrl();
    Wxconfig.wxShowMenu({
      titles: '归农',
      titles_circle: '归农',
      descs: '不负农人苦心，分享自然真味!我们一起归农...',
      link: link,
      imgUrl: 'https://gn-rrd.gnso.cn/2020/01/15/Fg2kPnOoWXygYdWWsSMNObSZ5YVV.jpg',
      ajaxUrl: '/weixin/sign_package_web.json?url=' + encodeURIComponent(link),
    });
  },

  methods: {
    getUrl() {
      return window.location.href.split('#')[0];
    },
  },
})
export default class AppMobile extends Vue {}
</script>

<style lang="scss">

#bottom {
  position: fixed;
  flex-direction: column;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #dcdcdc;
  z-index: 100001;
  font-size: calc(30/1080 *  100vw);
  min-height: calc(120/1080 *  100vw);
}

#tabber_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #dcdcdc;
}
</style>
